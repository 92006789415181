/**
 * @description 表单数据和设计数据合并解析出来详情的数据 作用：下载审批单的入参、审批流程详情获取数据
 * @param {Object} formData 表单的数据
 * @param {Array} formDesignList 设计数据
 * @param {Boolean} isDownLoad 是否是下载审批单用的，下载审批单的图片和附件只要展示名称就好，不需要文件id
 * @param {Array} formList 需要被组合的数据集合
 * @param {string} basePath 图片或者附件的统一公共前缀
*/
import { getSignatures } from '@/services/upload/index.js'
export const formateData = async(formData, formDesignList, isDownLoad, formList, basePath) => {
  for (let index = 0; index < formDesignList.length; index++) {
    const item = formDesignList[index]
    let label = ''
    let value = ''
    if (item.list && item.list.length > 0) {
      formList[index] = {
        list: [],
        summaryList: [],
        title: item.options.baseInfoList[0].label
      }
      Object.keys(formData[item.model]).forEach(key => {
        if (key !== 'list') {
          formList[index].summaryList.push(formData[item.model][key])
        }
      })
      for (let i = 0; i < formData[item.model].list.length; i++) {
        formList[index].list[i] = []
        formateData(formData[item.model].list[i], item.list, isDownLoad, formList[index].list[i], basePath)
      }
    } else {
      // 单独处理省市区,把省市区合并，详情单独展示
      if (item.type === 'WKThreeLevel') {
        const valueList = []
        for (let i = 0; i < item.options.baseInfoList.length; i++) {
          const element = item.options.baseInfoList[i]
          if (i <= 2 && formData[item.model][item.options.baseInfoList[i].model] && formData[item.model][item.options.baseInfoList[i].model].label) {
            valueList.push(formData[item.model][item.options.baseInfoList[i].model].label)
          }
          if (i < 2) continue
          if (i === 2) {
            value = valueList.join('')
          }
          if (i === 3) {
            value = formData[item.model].detail
          }
          label = element.label
          formList.push({
            name: label,
            value
          })
        }
        return
      }
      for (let i = 0; i < item.options.baseInfoList.length; i++) {
        // 套件里面循环每次清空一下value，否则将沿用上一次已经赋值过的值
        value = ''
        const key = item.options.baseInfoList[i]
        if (typeof formData[item.model] === 'string') {
          value = formData[item.model]
        } else if (formData[item.model] instanceof Array) {
          // 如果数据格式是array的则直接赋值，这个地方除了附件图片以外剩下的在标签中只取label的集合
          // 附件图片取名称和对应的id
          if (['WKUploadImg', 'WKUploadFile'].includes(item.type)) {
            if (isDownLoad) {
              value = formData[item.model].map(file => file.label).join(',')
            } else {
              value = formData[item.model]
              for (let i = 0; i < value.length; i++) {
                const k = value[i]
                const signatureRes = await getSignatures({ ids: k.value })
                const { id, signature, time } = signatureRes.data[0]
                if (k.type === 'image') {
                  k['url'] = `${basePath}/hrsaas-storage/image/thumbnail?id=${id}&width=${291}&height=${125}&signature=${signature}&expireTime=${time}`
                } else {
                  k['url'] = `${basePath}/hrsaas-storage/file?id=${id}&width=${291}&height=${125}&signature=${signature}&expireTime=${time}`
                }
              }
            }
          } else {
            value = formData[item.model].map(labelItem => {
              return labelItem.label
            }).join(',')
          }
        } else if (formData[item.model] instanceof Object) {
          // 如果是常规的{label: xx, value: xxx}则直接取值展示
          if (formData[item.model].label) {
            value = formData[item.model].label
          } else {
            // 非常规的，这里值分的是String的和object类型的
            if (typeof formData[item.model][key.model] === 'string' || typeof formData[item.model][key.model] === 'number') {
              value = formData[item.model][key.model]
              // 需要合并时间的套件
              const ContainsTimeSuite = [
                'WKOvertimeSuite', // 加班
                'WKOutsideSuite', // 外出
                'WKBusinessTripSuite', // 出差
                'WKLeaveSuite' // 请假
              ]
              // 判断需要合并时间
              if (ContainsTimeSuite.includes(item.type)) {
                let { startTime, endTime } = formData[item.model]
                const { startDate, endDate } = formData[item.model]
                // 用来判断上午下午
                const AMPM = [
                  {
                    code: 'am',
                    label: '上午'
                  },
                  {
                    code: 'pm',
                    label: '下午'
                  }
                ]
                // 判断获取到的是开始时间
                if (key.model === 'startDate' && startTime) {
                  const startTimeObj = AMPM.find(({ code }) => code === startTime)
                  if (startTimeObj) {
                    startTime = startTimeObj.label
                  }
                  value = startDate + ' ' + startTime
                }
                if (key.model === 'endDate' && endTime) {
                  const endTimeObj = AMPM.find(({ code }) => code === endTime)
                  if (endTimeObj) {
                    endTime = endTimeObj.label
                  }
                  value = endDate + ' ' + endTime
                }
              }
              // 判断补卡特殊处理 时间
              if (item.type === 'WKAppendPunchSuite') {
                const { punchDate, punchTime } = formData[item.model]
                if (key.model === 'punchDate') {
                  value = punchDate + ' ' + punchTime
                }
              }
            } else if (formData[item.model][key.model] instanceof Array) {
              console.log('文件文件', key)
              if (['WKPaySalarySuiteSubDetailFileId'].includes(key.type)) {
                console.log('是文件么？？？', value)
                if (isDownLoad) {
                  value = formData[item.model][key.model].map(file => file.label).join(',')
                } else {
                  value = formData[item.model][key.model]
                  for (let i = 0; i < value.length; i++) {
                    const k = value[i]
                    const signatureRes = await getSignatures({ ids: k.value })
                    const { id, signature, time } = signatureRes.data[0]
                    if (k.type === 'image') {
                      k['url'] = `${basePath}/hrsaas-storage/image/thumbnail?id=${id}&width=${291}&height=${125}&signature=${signature}&expireTime=${time}`
                    } else {
                      k['url'] = `${basePath}/hrsaas-storage/file?id=${id}&width=${291}&height=${125}&signature=${signature}&expireTime=${time}`
                    }
                  }
                }
              }
            } else {
              // object类型的
              value = formData[item.model][key.model].label
            }
          }
        }
        label = key.label
        formList.push({
          name: label,
          value
        })
      }
    }
  }
}
