import {
  BASE
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 查询当前审批的操作记录 - 时间轴
export async function getFlowRecordOperateList(params) {
  return request(`${BASE}/process/flowRecord/getFlowRecordOperateList`, METHOD.GET, params)
}

// 查询当前审批的操作记录 - 详情
export async function getFlowRecordDetail(params) {
  return request(`${BASE}/process/flowRecord/getFlowRecordDetail`, METHOD.GET, params)
}

// 表单详情查询【YAPI里面没有】单独转换表单详情用的
export async function flowRecordFormItem(params) {
  return request(`${BASE}/process/flowRecord/flowRecordFormItem`, METHOD.GET, params)
}
